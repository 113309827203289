import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Day from "./day"
import Kids from "./kids"
import moment from "moment"
import "moment/locale/fr"
moment.locale("fr")

const Week = ({ weekNum }) => {
  const filterDates = item => {
    let daynum = item.moment.format("E")
    let weeknum = item.moment.format("W")
    return (
      daynum !== "3" && daynum !== "6" && daynum !== "7" && weeknum === weekNum
    )
  }
  const data = useStaticQuery(graphql`
    query MyQuery {
      allMenusXlsxDates {
        nodes {
          entree
          dessert
          date(formatString: "YYYY-MM-DD")
          plat
        }
      }
    }
  `)
  const week = JSON.parse(JSON.stringify(data.allMenusXlsxDates.nodes))
    .map(item => {
      item.moment = moment(item.date)
      item.date = item.moment.format("YYYY-MM-DD")
      return item
    })
    .filter(filterDates)
  if (week.length > 0) {
    const firstDate =
      week[0].moment.format("MMMM") !==
      week[week.length - 1].moment.format("MMMM")
        ? week[0].moment.format("D MMMM")
        : week[0].moment.format("D")
    const lastDate = week[week.length - 1].moment.format("D MMMM")
    return (
      <div className="uk-margin-large-bottom uk-unbreak@p">
        <div
          className="uk-width-1 uk-text-center bg-white uk-hidden@m uk-hidden@p"
          uk-sticky="bottom: true"
        >
          <div className="bg-white inner-sticky">
            <p className="uk-text-emphasis uk-margin-remove">
              Menu de la semaine {weekNum < 10 ? "0" + weekNum : weekNum}
            </p>
            <h4 className="uk-margin-remove">
              Du {firstDate} au {lastDate}
            </h4>
          </div>
        </div>
        <div uk-grid="">
          <div className="uk-width-3-4@m uk-width-3-4@p uk-text-left uk-visible@m  uk-visible@p">
            <p className="uk-text-emphasis uk-margin-remove">
              Menu de la semaine {weekNum < 10 ? "0" + weekNum : weekNum}
            </p>
            <h2 className="uk-margin-remove-top uk-hidden@p">
              Du {firstDate} au {lastDate}
            </h2>
            <h4
              style={{ display: "none" }}
              className="uk-margin-remove-top uk-visible@p"
            >
              Du {firstDate} au {lastDate}
            </h4>
          </div>
          <div className="uk-width-1-4@m uk-width-1-4@p">
            <Kids />
          </div>
        </div>
        <div uk-height-match={"target: .entree-" + weekNum + "; row: false "}>
          <div uk-height-match={"target: .plat-" + weekNum + "; row: false "}>
            <div
              className="uk-grid uk-child-width-1-4@m uk-child-width-1-4@p uk-grid-small uk-grid-match"
              uk-grid=""
              uk-height-match={"target: .dessert-" + weekNum + "; row: false "}
            >
              {week.map((menuItem, i) => (
                <Day {...menuItem} key={menuItem.date} />
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
  return <div></div>
}

export default Week
