import React from "react"
import Week from "./week"
import moment from "moment"
const Weeks = () => {
  // var weekNum = moment().format('W');
  const weekNums = [
    moment().format("W"),
    moment()
      .add(1, "w")
      .format("W"),
   moment()
     .add(2, "w")
     .format("W"),
 //   moment()
 //     .add(3, "w")
 //     .format("W"),
 //   moment()
 //     .add(4, "w")
 //     .format("W"),
  ]
  console.log(weekNums)
  return (
    <div>
      {weekNums.map((weekNum, i) => (
        <Week weekNum={weekNum} key={"week-" + weekNum} />
      ))}
    </div>
  )
}

export default Weeks
