import React, { useEffect } from "react"
import Weeks from "../components/weeks"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => {
  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ title: "Fetch POST Request Example" }),
    }

    fetch("/", requestOptions)
      .then(response => response.json())
      .then(data => console.log(data))
      .catch(error => console.error(error))
  }, []) // Only re-run the effect if count changes
  return (
    <Layout>
      <SEO title="Accueil" />
      <Weeks />
    </Layout>
  )
}

export default IndexPage
