import React from "react"

const Day = ({ entree, dessert, date, plat, moment }) => {
  const dateFormated = moment.format("dddd")
  const weeknum = moment.format("W")
  return (
    <div>
      <div className="uk-card uk-card-primary uk-card-body uk-text-center uk-padding-small">
        <h3 className="uk-card-title uk-text-capitalize">{dateFormated}</h3>
        <div className={"uk-margin-bottom entree-" + weeknum}>
          {entree && (
            <div>
              <strong className="uk-text-emphasis">Entrée</strong>
              <p className="uk-margin-remove uk-text-small">{entree}</p>
            </div>
          )}
        </div>
        <div className={"uk-margin-bottom plat-" + weeknum}>
          {plat && (
            <div>
              <strong className="uk-text-emphasis">Plat</strong>
              <p className="uk-margin-remove uk-text-small">{plat}</p>
            </div>
          )}
        </div>
        <div className={"uk-margin-bottom dessert-" + weeknum}>
          {dessert && (
            <div>
              <strong className="uk-text-emphasis">Dessert</strong>
              <p className="uk-margin-remove uk-text-small">{dessert}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Day
